import React, { useRef, useState, ReactNode, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import Pusher from 'pusher-js';
import { setLicenseKey as setSurveyJSLicense } from 'survey-core';
import {
	Grid,
	Box,
	AppBar,
	Toolbar,
	Avatar,
	Typography,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Theme,
	Badge as MuiBadge,
} from '@mui/material';
import {
	Menu as MenuIcon,
	ArrowBackIosRounded as ArrowBackIosRoundedIcon,
	KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon,
	ExpandMoreOutlined as ExpandMoreOutlinedIcon,
} from '@mui/icons-material';
import { sideMenuOptions } from './sideMenuOptions';
import logo from '@/assets/icons/Logo.svg';
import NotificationIcon from '@/assets/icons/NotificationFilledIcon.svg';
import HelpIcon from '@/assets/icons/HelpCircleIcon.svg';
import MenuLeft from '@/assets/icons/MenuLeft.svg';
import MenuRight from '@/assets/icons/MenuRight.svg';
import {
	Badge,
	Button,
	HelpMenu,
	Loader,
	NotificationsMenu,
	PasswordChangeAlert,
	ProfileMenu,
	TermsAndConditions,
	WorkspaceMenu,
} from '@/components';
import useHubspotChat from '@/hooks/useHubspotChat';
import { useLogout } from '@/hooks/useLogout';
import useMixpanel from '@/hooks/useMixpanel';
import useNotification from '@/hooks/useNotification';
import {
	LOGOUT,
	GET_AUTH_ME,
	RESET_IMPERSONATED_USER,
	RESET_CHIPS_FILTERS,
} from '@/store/actions';
import { useAuthService } from '@/store/services/auth.service';
import { useHelpDocsService } from '@/store/services/helpDocs.service';
import { useHelpSectionsService } from '@/store/services/helpSections.service';
import { useNotificationService } from '@/store/services/notification.service';
import { RootState } from '@/store/slices';
import {
	APP_ROUTES,
	APP_VERSION,
	IS_STAGING,
	PUSHER_KEY,
	PUSHER_CLUSTER,
	PAGINATION_INITIAL_STATE,
	SURVEYJS_LICENSE_KEY,
	TASK_VIEWS,
} from '@/utils/constants';
import {
	isSuperAdmin,
	optimizeImg,
	canAccessRoute,
	isRecentNotification,
} from '@/utils/helpers';
import {
	HelpDocType,
	HelpSectionType,
	NotificationType,
	PaginationMetaType,
	SidebarMenuType,
	SubmenuItemsType,
} from '@/utils/types';

setSurveyJSLicense(SURVEYJS_LICENSE_KEY);

type MakeStyle = {
	isDrawerOpen: boolean;
	showSubmenu: string | null;
};

type BaseLayoutPropType = {
	children: ReactNode;
};

const makeStyles = ({ isDrawerOpen, showSubmenu }: MakeStyle) => ({
	root: {
		minHeight: '100vh',
		transition: 'ease-in-out 500ms',
	},
	appBar: {
		transition: 'ease-in-out 300ms',
		backgroundColor: '#fff',
		borderBottom: (theme: Theme) => `1px solid ${theme.borderColor.toolbar}`,
		'&.MuiToolbar-root': {
			minHeight: 53,
		},
		borderTop: IS_STAGING ? '2px solid #A94943' : 'none',
	},
	drawerPaper: {
		borderRight: '1px solid #eef0f3',
		top: { xs: 0, sm: 53 },
		overflow: 'auto',
		height: 'calc(100vh - 53px)',
	},
	drawerContent: {
		overflow: 'auto',
		width: isDrawerOpen ? 240 : 68,
		height: '100%',
		transition: 'ease-in-out 300ms',
		padding: { xs: '12px 10px 0', sm: '24px 10px 0' },
	},
	container: {
		left: { xs: 0, sm: isDrawerOpen ? 240 : 68 },
		transition: 'ease-in-out 300ms',
		right: 0,
		bottom: 0,
		top: 53,
		position: 'fixed',
		overflowY: 'auto',
	},
	avatar: { width: '24px', height: '24px' },
	iconButtons: {
		color: (theme: Theme) => theme.palette.text.secondary,
	},
	itemBorderLeft: {
		borderLeft: '1px solid #F1F7FA',
	},
	itemBorderRight: {
		borderRight: '1px solid #F1F7FA',
	},
	appBarTextColor: { color: (theme: Theme) => theme.textColor.primary },
	menuBtn: { pb: '12px', pl: '12px' },
	listBtn: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		padding: '12px 10px',
		justifyContent: isDrawerOpen ? 'initial' : 'center',
		borderBottomLeftRadius: showSubmenu ? '0px' : '8px',
		borderBottomRightRadius: showSubmenu ? '0px' : '8px',
		borderTopLeftRadius: '8px',
		borderTopRightRadius: '8px',
	},
	listIcon: {
		minWidth: 0,
		mr: isDrawerOpen ? 1.2 : 'auto',
	},
	listText: {
		fontSize: '13px',
		fontFamily: (theme: Theme) => theme.typography,
		opacity: isDrawerOpen ? 1 : 0,
		fontWeight: 600,
		lineHeight: '16px',
		whiteSpace: 'nowrap',
		color: (theme: Theme) => theme.textColor.primary,
	},
	submenuContainer: {
		borderBottomLeftRadius: '8px',
		borderBottomRightRadius: '8px',
		backgroundColor: '#F3F8F9',
		p: '0 12px 10px 10px',
	},
	submenuItems: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
		p: '10px 12px 10px 39px',
		cursor: 'pointer',
		transition: 'all 0.5s ease',
		borderRadius: '5px',
		'&:hover': {
			background: (theme: Theme) => theme.borderColor.searchField,
			pl: '46px',
		},
		'&.Mui-selected': {
			background: (theme: Theme) => theme.borderColor.searchField,
			borderRadius: '5px',
		},
	},
	subItemsText: {
		fontWeight: 600,
		fontFamily: (theme: Theme) => theme.typography,
		fontSize: '13px',
		lineHeight: '16px',
		color: (theme: Theme) => theme.textColor.primary,
	},
	organizationContainer: {
		display: 'flex',
		justifyContent: 'row',
		alignItems: 'center',
		backgroundColor: 'white',
		padding: '0 16px',
		marginLeft: 1,
		cursor: 'pointer',
	},
	organizationName: {
		lineHeight: '21px',
		fontSize: '16px',
		color: 'rgb(96, 110, 119)',
	},
	notificationBadge: {
		span: {
			top: '5px',
			backgroundColor: (theme: Theme) => theme.palette.error.main,
			color: '#fff',
			fontSize: '10px',
			width: '21px',
			height: '21px',
			borderRadius: '50%',
		},
	},
});

// stackoverflow.com/a/179514
const deleteAllCookies = () => {
	const cookies = document.cookie.split(';');
	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i];
		const eqPos = cookie.indexOf('=');
		const cookieName = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
		document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	}
};

const PUSHER_EVENT_NAME = 'notification';

export const BaseLayout = ({ children }: BaseLayoutPropType) => {
	const router = useRouter();
	const dispatch = useDispatch();
	const { track, resetMixpanel } = useMixpanel();
	const { logout } = useLogout();
	const { sendNotification } = useNotification();
	const { closeHandler } = useHubspotChat();
	const { enqueueSnackbar } = useSnackbar();

	const [helpDocs, setHelpDocs] = useState<HelpDocType[]>([]);
	const [helpSections, setHelpSections] = useState<HelpSectionType[]>([]);
	const [menuHelpDocs, setMenuHelpDocs] = useState<HelpDocType[]>([]);
	const [isDrawerOpen, setDrawerOpen] = useState(false);
	const [showSubmenu, setShowSubmenu] = useState<string | null>(null);
	const [profileAnchor, setProfileAnchor] = useState<HTMLDivElement | null>(null);
	const [helpAnchor, setHelpAnchor] = useState<HTMLDivElement | null>(null);
	const [notificationAnchor, setNotificationAnchor] = useState<HTMLDivElement | null>(
		null,
	);
	const [workspaceAnchor, setWorkspaceAnchor] = useState<HTMLDivElement | null>(null);
	const [selectedTab, setSelectedTab] = useState<number>(0);
	const [readNotificationsData, setReadNotificationsData] = useState<NotificationType[]>(
		[],
	);
	const [unreadNotificationsData, setUnreadNotificationsData] = useState<
		NotificationType[]
	>([]);
	const [unreadNotificationsCount, setUnreadNotificationsCount] = useState<number>(0);
	const [paginationMeta, setPaginationMeta] = useState<PaginationMetaType>(
		PAGINATION_INITIAL_STATE as PaginationMetaType,
	);

	const { getHelpDocs } = useHelpDocsService();
	const { getHelpSections } = useHelpSectionsService();
	const {
		getNotifications,
		markAllNotificationsAsRead,
		markNotificationAsRead,
		markNotificationAsUnread,
		isLoadingNotifications,
	} = useNotificationService();

	const { data: user, error: user_error } = useSelector((state: RootState) => state.me);
	const { error: auth_error, impersonatedUserId } = useSelector(
		(state: RootState) => state.auth,
	);
	const { switchingToWorkspace, currentView } = useSelector(
		(state: RootState) => state.layout,
	);
	const { getLoggedInUserDetails } = useAuthService();

	const drawerRef = useRef<HTMLDivElement>(null);

	const handleSetProfileAnchor = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		setProfileAnchor(e.currentTarget);
	};

	const handleSetHelpAnchor = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		setHelpAnchor(e.currentTarget);
	};

	const handleSetNotificationAnchor = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		setNotificationAnchor(e.currentTarget);
	};

	const handleSetWorkspaceAnchor = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		setWorkspaceAnchor(e.currentTarget);
	};

	const styles = makeStyles({
		isDrawerOpen,
		showSubmenu,
	});

	const submenuItems =
		showSubmenu === 'Tasks'
			? TASK_VIEWS.map((view) => ({
					name: view.name,
					path: `/tasks/${view.id}`,
			  }))
			: helpSections.map((h) => ({
					name: h.name,
					path: `/help-docs?section=${h.slug}`,
			  }));

	const getHelpDocsCallback = useCallback(async () => {
		const result = await getHelpDocs();
		setHelpDocs(result);
	}, [getHelpDocs]);

	const getHelpSectionsCallback = useCallback(async () => {
		const sections = await getHelpSections();
		setHelpSections(sections);
	}, [getHelpSections]);

	const getSideMenuOptions = () => {
		if (user) {
			return isSuperAdmin(user)
				? sideMenuOptions
				: (sideMenuOptions as SidebarMenuType[]).filter((option) =>
						canAccessRoute(user, APP_ROUTES.find((route) => route.path === option.path)!),
				  );
		} else {
			return [];
		}
	};

	const handleToggleDrawer = () => {
		setDrawerOpen(!isDrawerOpen);
	};

	const handleSubmenu = (name: string) => {
		setShowSubmenu(name);
	};

	const getNotificationsCallback = useCallback(
		async (page: number, isRead: boolean) => {
			const result = await getNotifications({
				isRead,
				page,
			});

			if (page === 1 && selectedTab === 0 && isRead === false) {
				setUnreadNotificationsData(result.data);
			} else if (isRead === false && paginationMeta.page > 1) {
				setUnreadNotificationsData((prev) => [...prev, ...result.data]);
			}

			if (page === 1 && selectedTab === 1 && isRead === true) {
				setReadNotificationsData(result.data);
			} else if (isRead === true && paginationMeta.page > 1) {
				setReadNotificationsData((prev) => [...prev, ...result.data]);
			}

			if (selectedTab === 0) {
				setUnreadNotificationsCount(result.extra.recentUnreadCount);
			}

			setPaginationMeta(result.meta);
		},
		[getNotifications, paginationMeta.page, selectedTab],
	);

	const handleNewNotificationsCallback = useCallback(async () => {
		const result = await getNotifications({
			isRead: false,
			page: 1,
		});

		if (result.meta.itemCount > paginationMeta.itemCount && !isLoadingNotifications) {
			const newNotificationCount = result.meta.itemCount - paginationMeta.itemCount;

			setPaginationMeta((prevMeta) => ({
				...prevMeta,
				itemCount: result.meta.itemCount,
			}));

			setUnreadNotificationsData((prev) => {
				prev.unshift(...result.data.slice(0, newNotificationCount));
				return [...prev];
			});
		}

		setUnreadNotificationsCount(result.extra.recentUnreadCount);
	}, [getNotifications, isLoadingNotifications, paginationMeta.itemCount]);

	const handleMarkAllAsReadNotificationsCallback = useCallback(async () => {
		markAllNotificationsAsRead();
		setUnreadNotificationsCount(0);
		setUnreadNotificationsData([]);
		track('All Notifications Marked as Read', {
			notification_count: unreadNotificationsCount,
		});
	}, [markAllNotificationsAsRead, track, unreadNotificationsCount]);

	const handleMarkAsReadNotificationCallback = useCallback(
		async (notificationId: string) => {
			const result = await markNotificationAsRead(notificationId);
			if (result) {
				if (
					isRecentNotification(
						unreadNotificationsData.find((n) => n.id === notificationId)!,
					)
				) {
					setUnreadNotificationsCount((prev) => prev - 1);
				}
				setUnreadNotificationsData((prev) =>
					prev.filter((notification) => notification.id !== notificationId),
				);
			}
		},
		[markNotificationAsRead, unreadNotificationsData],
	);

	const handleMarkAsUnreadNotificationCallback = useCallback(
		async (notificationId: string) => {
			const result = await markNotificationAsUnread(notificationId);
			if (result) {
				if (
					isRecentNotification(
						readNotificationsData.find((n) => n.id === notificationId)!,
					)
				) {
					setUnreadNotificationsCount((prev) => prev + 1);
				}
				setReadNotificationsData((prev) =>
					prev.filter((notification) => notification.id !== notificationId),
				);
			}
		},
		[markNotificationAsUnread, readNotificationsData],
	);

	// If in help docs pages
	useEffect(() => {
		if (!helpDocs.length) return;

		// Redirect to first help doc from section when help docs page is loaded
		if (router.pathname === '/help-docs') {
			const doc = helpDocs.find(
				(h: HelpDocType) =>
					h.helpSection.slug === (router.query.section || 'getting_started'),
			);
			router.push(`/help-docs/${doc!.id}`);
			return;
		}

		// Make left sidebar help docs visible
		if (router.pathname === '/help-docs/[id]') {
			setDrawerOpen(true);
			setTimeout(() => setShowSubmenu('Help Docs'), 0);
			setTimeout(() => {
				const scrollElement = drawerRef.current;
				if (scrollElement) {
					scrollElement.scrollTop = scrollElement.scrollHeight;
				}
			}, 900);
		}
	}, [router, helpDocs]);

	// Set help docs in contextual menu
	useEffect(() => {
		const path = router.asPath.split('?')[0];
		const matchedHelpDocs = helpDocs.filter(
			(h) => !h.isDraft && h.url && path.match(new RegExp(h.url)),
		);
		setMenuHelpDocs(matchedHelpDocs);
	}, [router.asPath, helpDocs]);

	// Check for app updates every 5 minutes
	useEffect(() => {
		const checkUpdates = async () => {
			const response = await fetch(`/api/check-updates?version=${APP_VERSION}`);
			const { refreshRequired } = await response.json();
			if (refreshRequired) {
				enqueueSnackbar(
					'Please refresh the page to get the latest version of Tablecloth',
					{
						variant: 'info',
						autoHideDuration: null,
						anchorOrigin: { horizontal: 'center', vertical: 'top' },
					},
				);
			}
		};
		const intervalId = setInterval(checkUpdates, 300000);
		return () => clearInterval(intervalId);
	}, [enqueueSnackbar]);

	useEffect(() => {
		const pusher = new Pusher(PUSHER_KEY, { cluster: PUSHER_CLUSTER });
		const channel = pusher.subscribe(`userId-${user?.id}`);

		channel.bind(PUSHER_EVENT_NAME, async () => {
			await handleNewNotificationsCallback();
		});

		return () => {
			pusher.unsubscribe(`userId-${user?.id}`);
			pusher.disconnect();
		};
	}, [handleNewNotificationsCallback, user?.id]);

	useEffect(() => {
		if (!notificationAnchor) {
			getNotificationsCallback(1, false);
		}
	}, [getNotificationsCallback, notificationAnchor]);

	useEffect(() => {
		getHelpDocsCallback();
	}, [getHelpDocsCallback]);

	useEffect(() => {
		getHelpSectionsCallback();
	}, [getHelpSectionsCallback]);

	useEffect(() => {
		if (isDrawerOpen === false) {
			setShowSubmenu(null);
		}
	}, [isDrawerOpen, showSubmenu]);

	useEffect(() => {
		if (switchingToWorkspace) {
			setTimeout(() => router.reload(), 3000);
		}
	}, [router, switchingToWorkspace]);

	const handleProfile = () => {
		setDrawerOpen(false);
		router.push('/profile');
	};

	//TODO: The global error handling can handle in a single state in redux toolkit.
	useEffect(() => {
		if (auth_error || user_error) {
			sendNotification({
				msg: auth_error ? auth_error.message : user_error?.message,
				variant: 'error',
			});
		}
	}, [auth_error, user_error, sendNotification]);

	const handleLogout = useCallback(async () => {
		await track('User Logged Out');
		deleteAllCookies();
		closeHandler();
		logout({
			async openUrl() {
				window.location.replace('/login');
			},
		});
		dispatch(RESET_CHIPS_FILTERS());
		dispatch(LOGOUT());
		dispatch(RESET_IMPERSONATED_USER());
		resetMixpanel();
	}, [closeHandler, logout, dispatch, track, resetMixpanel]);

	const handleMultiRoute = (name: string) => {
		if (isDrawerOpen) {
			setShowSubmenu(showSubmenu === name ? null : name);
		} else {
			setShowSubmenu(null);
			router.push(name === 'Tasks' ? `/tasks/${currentView}` : '/help-docs');
		}
	};

	const getAuthMe = useCallback(() => {
		dispatch(GET_AUTH_ME({ getLoggedInUserDetails }));
	}, [dispatch, getLoggedInUserDetails]);

	const loginAsTcUser = useCallback(() => {
		dispatch(RESET_IMPERSONATED_USER());
		getAuthMe();
		track('Switched Back To Admin');
	}, [dispatch, getAuthMe, track]);

	const shouldHighlightSubmenuItem = (item: SubmenuItemsType) => {
		if (router.pathname === '/help-docs/[id]' && helpDocs.length) {
			const section = helpDocs.find((h) => h.id === router.query.id)?.helpSection.slug;
			return item.path.match(/\/help-docs\?section=(.+)/)?.[1] === section;
		} else {
			return router.asPath === item.path;
		}
	};

	return (
		<Box sx={styles.root}>
			<AppBar elevation={0}>
				<Toolbar disableGutters sx={styles.appBar}>
					<Grid
						container
						justifyContent="space-between"
						alignItems="center"
						height="52px"
					>
						<Grid item px="10px" pt="5px" sx={styles.itemBorderRight}>
							<Grid
								container
								alignItems="center"
								sx={{
									display: {
										xs: 'none',
										sm: 'block',
										cursor: 'pointer',
									},
								}}
								onClick={() => {
									router.push('/');
								}}
							>
								<Image src={logo} alt="logo" height={38} />
							</Grid>
							<Grid
								container
								alignItems="center"
								sx={{ display: { xs: 'block', sm: 'none' } }}
							>
								<IconButton onClick={handleToggleDrawer}>
									<MenuIcon />
								</IconButton>
							</Grid>
						</Grid>
						{user && isSuperAdmin(user) ? (
							<></>
						) : (
							<Grid
								item
								sx={styles.organizationContainer}
								onClick={handleSetWorkspaceAnchor}
							>
								<Typography sx={styles.organizationName}>
									{
										user?.userOrganizationGroups.find(
											(orgGroups) => orgGroups.isDefaultAccount,
										)!.organization.name
									}
								</Typography>
								<IconButton sx={styles.iconButtons}>
									<ExpandMoreOutlinedIcon />
								</IconButton>
							</Grid>
						)}
						<Grid item flexGrow={1}>
							{impersonatedUserId ? (
								<Grid container justifyContent="flex-end" pr={2}>
									<Button
										text="Back to your account"
										customStyles={{ height: 35 }}
										onClick={loginAsTcUser}
									/>
								</Grid>
							) : (
								<></>
							)}
						</Grid>
						<Grid item sx={styles.itemBorderLeft} p={2} height="inherit">
							<Grid onMouseEnter={handleSetHelpAnchor} sx={{ p: 0, cursor: 'pointer' }}>
								<Image src={HelpIcon} alt="Help" />
							</Grid>
						</Grid>
						<Grid item sx={styles.itemBorderLeft} p={2} height="inherit">
							<Grid
								onClick={handleSetNotificationAnchor}
								sx={{ p: 0, cursor: 'pointer' }}
							>
								<MuiBadge
									badgeContent={unreadNotificationsCount}
									color="error"
									sx={styles.notificationBadge}
								>
									<Image src={NotificationIcon} alt="Notifications" />
								</MuiBadge>
							</Grid>
						</Grid>
						<Grid item px="9px" sx={styles.itemBorderLeft} height="inherit">
							<Grid
								container
								alignItems="center"
								gap={1}
								height="inherit"
								onClick={handleSetProfileAnchor}
								sx={{ cursor: 'pointer' }}
							>
								<Avatar
									alt={'profile'}
									src={optimizeImg(user?.pictureUrl as string, 32)}
									sx={styles.avatar}
								/>
								<Typography
									sx={{
										...styles.appBarTextColor,
										display: { xs: 'none', md: 'block' },
									}}
									variant="body2"
								>
									{user?.userName}
								</Typography>
								<IconButton sx={styles.iconButtons}>
									<ExpandMoreOutlinedIcon />
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<>
				<Drawer
					open
					variant="persistent"
					PaperProps={{ sx: styles.drawerPaper }}
					sx={{ display: { xs: 'none', sm: 'block' } }}
				>
					<Grid
						ref={drawerRef}
						container
						direction="column"
						sx={styles.drawerContent}
						flexWrap="nowrap"
					>
						<Grid item flexGrow={1}>
							<List>
								{getSideMenuOptions().map(({ name, icon, path, isMulti }) => (
									<Box key={name} sx={{ py: '6px' }}>
										<ListItem disablePadding sx={{ py: isMulti ? '0px' : '6px' }}>
											<ListItemButton
												sx={{
													...styles.listBtn,
													background: isMulti && showSubmenu === name ? '#F3F8F9' : '',
												}}
												onClick={() =>
													isMulti ? handleMultiRoute(name) : router.push(path)
												}
												selected={router.asPath === path}
											>
												<ListItemIcon sx={styles.listIcon}>
													<Image src={icon} alt="" />
												</ListItemIcon>
												<Box sx={{ flex: '1 1 auto' }}>
													{isDrawerOpen && (
														<Typography sx={styles.listText}>{name}</Typography>
													)}
												</Box>
												{isDrawerOpen && isMulti ? (
													<Box
														onClick={() => handleSubmenu(name)}
														sx={{ textAlign: 'right', pr: '18px' }}
													>
														{showSubmenu === name ? (
															<KeyboardArrowUpIcon />
														) : (
															<KeyboardArrowDownIcon />
														)}
													</Box>
												) : null}
											</ListItemButton>
										</ListItem>
										{showSubmenu === name && isMulti && (
											<Box sx={styles.submenuContainer}>
												{submenuItems.map((item: SubmenuItemsType) => (
													<ListItem disablePadding key={item.name}>
														<ListItemButton
															sx={styles.submenuItems}
															onClick={() => router.push(item.path)}
															selected={shouldHighlightSubmenuItem(item)}
														>
															<Box>
																<Box sx={styles.subItemsText}>{item.name}</Box>
															</Box>
															{typeof item.count === 'number' && (
																<Badge count={item.count} />
															)}
														</ListItemButton>
													</ListItem>
												))}
											</Box>
										)}
									</Box>
								))}
							</List>
						</Grid>
						<Grid item>
							<IconButton sx={styles.menuBtn} onClick={handleToggleDrawer}>
								<Image src={isDrawerOpen ? MenuRight : MenuLeft} alt="" />
							</IconButton>
						</Grid>
					</Grid>
				</Drawer>
				<Drawer
					open={isDrawerOpen}
					variant="temporary"
					PaperProps={{ sx: styles.drawerPaper }}
					sx={{ display: { xs: 'block', sm: 'none' } }}
					onClose={handleToggleDrawer}
				>
					<Grid container direction="column" sx={styles.drawerContent}>
						<Grid
							item
							sx={{
								borderBottom: (theme: Theme) => {
									return `1px solid ${theme.borderColor.toolbar}`;
								},
								paddingBottom: 1,
							}}
						>
							<Grid container justifyContent="space-between">
								<Image src={logo} alt="logo" height={38} />
								<IconButton onClick={handleToggleDrawer}>
									<ArrowBackIosRoundedIcon />
								</IconButton>
							</Grid>
						</Grid>
						<Grid item flexGrow={1}>
							<List>
								{getSideMenuOptions().map(({ name, icon, path }) => (
									<ListItem key={name} disablePadding sx={{ py: '6px' }}>
										<ListItemButton
											sx={styles.listBtn}
											onClick={() => {}}
											selected={path === router.pathname}
										>
											<ListItemIcon sx={styles.listIcon}>
												<Image src={icon} alt="" />
											</ListItemIcon>
											{isDrawerOpen && (
												<ListItemText primary={name} sx={styles.listText} />
											)}
										</ListItemButton>
									</ListItem>
								))}
							</List>
						</Grid>
					</Grid>
				</Drawer>
			</>
			<Box component="main" sx={styles.container}>
				{switchingToWorkspace ? (
					<Loader
						loadingText={`Switching to the following account: ${switchingToWorkspace} `}
					/>
				) : (
					children
				)}
			</Box>
			<WorkspaceMenu
				anchorEl={workspaceAnchor}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				handleClose={() => {
					setWorkspaceAnchor(null);
				}}
			/>
			<ProfileMenu
				anchorEl={profileAnchor}
				profileHandler={handleProfile}
				handleClose={() => {
					setProfileAnchor(null);
				}}
				handleLogout={handleLogout}
			/>
			{!isEmpty(user) && (
				<>
					<NotificationsMenu
						anchorEl={notificationAnchor}
						handleClose={() => {
							setNotificationAnchor(null);
							setSelectedTab(0);
						}}
						open={Boolean(notificationAnchor)}
						readNotificationsData={readNotificationsData}
						unreadNotificationsData={unreadNotificationsData}
						paginationMeta={paginationMeta}
						setPaginationMeta={setPaginationMeta}
						getNotificationsCallback={getNotificationsCallback}
						selectedTab={selectedTab}
						setSelectedTab={setSelectedTab}
						isLoadingNotifications={isLoadingNotifications}
						markAllAsReadCallback={handleMarkAllAsReadNotificationsCallback}
						markNotificationAsReadCallback={handleMarkAsReadNotificationCallback}
						markNotificationAsUnreadCallback={handleMarkAsUnreadNotificationCallback}
					/>
					<HelpMenu
						anchorEl={helpAnchor}
						handleClose={() => {
							setHelpAnchor(null);
						}}
						isLoading={isLoadingNotifications}
						helpDocs={menuHelpDocs}
					/>
				</>
			)}
			{!impersonatedUserId && <PasswordChangeAlert />}
			{!impersonatedUserId && <TermsAndConditions />}
		</Box>
	);
};
