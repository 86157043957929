import { createSlice } from '@reduxjs/toolkit';
import { capitalize, getTab } from '@/utils/helpers';
import { SURVEY_TABS_LABELS } from '@/utils/constants';

type InitialStateType = {
	currentTab: number;
};

let tab: string | undefined;

if (typeof window !== 'undefined' && location.pathname === '/surveys') {
	tab = getTab();
}

const initialState: InitialStateType = {
	currentTab: tab ? SURVEY_TABS_LABELS.indexOf(capitalize(tab)) : 0,
};

const prepare = (
	payload?: Record<string, any>,
	meta?: Record<string, any>,
	error?: Record<string, any>,
) => {
	return { payload, meta, error };
};

export const surveySlice = createSlice({
	name: 'survey',
	initialState,
	reducers: {
		SET_CURRENT_TAB: {
			reducer: (state, { payload }) => {
				state.currentTab = payload.currentTab;
			},
			prepare,
		},
	},
});

export const { SET_CURRENT_TAB } = surveySlice.actions;

export default surveySlice.reducer;
